import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It's been a while since I've written you one of these letters. I'm sorry about that. I plan on being better about it, but so much has happened that distracted me.`}</p>
    <p>{`Where to start?`}</p>
    <p>{`Well, we got married! Can you believe it? It's surreal; like, I'm your husband and that's wild. And being your husband is an overwhelming honor; I love you and am so proud of you. You're an incredible, awe-inspiring woman; seeing your strength over these past few months as you've dealt with the hardship of losing your grandmother and cleaning up her house has blown me away. I know you've been having a rough time with it and don't always feel like your old self, but that's alright. It gets easier with time, you've made tremendous progress, and I always be here to support you.`}</p>
    <p>{`Switching gears. We did 2/3 of our honeymoon. We were on a boat! That was an incredible vacation. I wish I could lounge on the beach with you all the time. We have to do it again sometime. And I'm looking forward to finishing our honeymoon; AVENGERS CAMPUS!`}</p>
    <p>{`Lastly, we bought a house. Our own home. I never thought I would own a home, but here we are. Thank you for putting the money down on it. I can't wait to move in, cook a fabulous dinner for you in our glorious kitchen, swim with you in the pool, decorate and furnish, host our family for events, and eventually have a kid. What a wonderful place it'll be to raise a kid, it's like a dream and I'm crying just thinking about it.`}</p>
    <p>{`This has been a rambling mess.`}</p>
    <p>{`I love you, Becks. God, I love you more than I know how to express.`}</p>
    <p className="signature">
	<span>&mdash;</span> Amir
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      